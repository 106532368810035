import { Observable } from 'rxjs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

import { TabsComponent } from '../tabs.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [SafeHtmlPipe, TabsComponent],
  declarations: [SafeHtmlPipe, TabsComponent],
})
export class UtilModule {}
